import '../assets/Registration.css';
import { TitleBar } from '../components/TitleBar';

export const RegistrationPage = () => {
    return (
        <div>
            <TitleBar title='Registration'/>
            <div className='title'>Youth and High School</div>
            <p>Youth & HS player registration will be on our new platform, TeamSnap. Signing up for a TeamSnap profile is broadly similar to signing up for a Rugby Xplorer profile, but it will be more particular to Silicon Valley Rugby.</p>
            <div className='title'>Adult</div>
            <p>Adult Registration will be handled through <a href="https://xplorer.rugby/">Rugby Xplorer</a></p>
            <ol>
                <li>Log In or Create an Account</li>
                <li>Click Register and Find a Club</li>
                <li>Search for "Silicon Valley Rugby". Select SENIOR CLUB.</li>
                <li>Select Role "Player". Type "Senior Club". Duration "Season".</li>
                <li>Fill in the personal information questions and pay for your registration.</li>
                <li>Once all of this is complete, you should get a reciept, and you are ready to play Rugby with Silicon Valley!</li>
            </ol>
        </div>
    );
}